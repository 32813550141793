<script>
import { Pie } from 'vue-chartjs';

export default {
    extends: Pie,
    props: {
        label: String,
        options: {
            type: Object,
            default: null,
        },
    },
    mounted() {},
    computed: {
        stats() {
            return this.$store.state.tiposDocumentos.info;
        },
    },
    watch: {
        stats: function (val) {
            const estados = this.stats.cantidades.por_estado.map(
                (item) => item.estado
            );
            const valores = this.stats.cantidades.por_estado.map(
                (item) => item.cantidad
            );
            const backgroundColors = this.stats.cantidades.por_estado.map(
                (item) => `#${item.hex}`
            );

            this.renderChart(
                {
                    labels: estados,
                    datasets: [
                        {
                            label: this.label,
                            data: valores,
                            backgroundColor: backgroundColors,
                        },
                    ],
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Chart.js Pie Chart',
                        },
                    },
                }
            );
        },
    },
};
</script>

<style></style>
