<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-item has-text-centered">
                <div>
                    <p class="heading">Documentos</p>
                    <p class="title">{{ stats.cantidades.documentos }}</p>
                </div>
            </div>
            <div class="level-item has-text-centered">
                <div>
                    <p class="heading">% Firmados</p>
                    <p class="title">{{ stats.estadisticas.firmados_ratio | percent }}</p>
                </div>
            </div>
            <div class="level-item has-text-centered">
                <div>
                    <p class="heading">Observaciones</p>
                    <p class="title">0</p>
                </div>
            </div>
        </nav>
        <pie-chart label="Estados" />
    </div>
</template>

<script>
import PieChart from './PieChart.vue';

export default {
    components: {
        PieChart
    },
    computed: {
        stats() {
            return this.$store.state.tiposDocumentos.info;
        }
    },
    mounted() {
        this.fetchStats(this.$route.params.id);
    },
    methods: {
        async fetchStats(id) {
            await this.$store.dispatch('tiposDocumentos/getStats', id);
        }
    },
    filters: {
        percent: function(value) {
            return `${ Math.round(value * 10000) / 100 }%`;
        }
    }
};
</script>

<style></style>
