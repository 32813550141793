<template>
    <div>
        <div class="content">
            <router-link to="/a/documentos/tipos-documentos" class="ml-2">
                <b-icon icon="arrow-left" size="is-small" />Atrás
            </router-link>
            <div class="text-input">
                <input v-model="descripcion" />
                <div class="controls">
                    <b-tooltip label="Guardar cambios">
                        <a href="#" role="button" @click.prevent="save()">
                            <b-icon icon="check" />
                        </a>
                    </b-tooltip>
                    <b-tooltip label="Cancelar">
                        <a href="#" role="button" @click.prevent="cancel()">
                            <b-icon icon="close" />
                        </a>
                    </b-tooltip>
                </div>
            </div>
        </div>
        <div class="is-relative">
            <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
            <div class="tile">
                <div class="tile is-parent">
                    <article class="tile is-child box is-relative">
                        <div class="content">
                            <h4>
                                Resumen Documento
                                <b-tag
                                    class="ml-2"
                                    :type="
                                        documento.activo
                                            ? 'is-success'
                                            : 'is-danger'
                                    "
                                    >{{
                                        documento.activo
                                            ? 'Activo'
                                            : 'No Activo'
                                    }}</b-tag
                                >
                            </h4>
                            <br />
                            <info />
                        </div>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box is-relative">
                        <div class="content">
                            <h4>Configuración</h4>
                            <br />
                            <b-field>
                                <b-switch
                                    size="is-medium"
                                    type="is-primary"
                                    passive-type="default"
                                    :value="documento.activo"
                                    :rounded="false"
                                    @input="toggleActivo(!documento.activo)"
                                >
                                    <b>{{
                                        documento.activo
                                            ? 'ACTIVO'
                                            : 'NO ACTIVO'
                                    }}</b>
                                </b-switch>
                            </b-field>
                            <hr />
                            <!-- FIRMA DEL TRABAJADOR -->
                            <div class="columns">
                                <div class="column">
                                    <b-field>
                                        <b-switch
                                            size="is-medium"
                                            type="is-primary"
                                            passive-type="default"
                                            v-model="require_firma_temp"
                                            :rounded="false"
                                            :disabled="!activo"
                                            @input="toggleRequiereFirma"
                                        >
                                            <span
                                                >DOCUMENTO REQUIERE FIRMA DEL
                                                <b>TRABAJADOR</b></span
                                            >
                                        </b-switch>
                                    </b-field>
                                    <br />
                                    <transition name="slide">
                                        <configuracion
                                            v-if="require_firma"
                                            firmante="TRABAJADOR"
                                            :tipo_documento_id="
                                                $route.params.id
                                            "
                                            :only_link="true"
                                        />
                                    </transition>
                                </div>
                                <div class="is-divider-vertical"></div>
                                <!-- FIRMA DEL EMPLEADOR -->
                                <div class="column">
                                    <b-field>
                                        <b-switch
                                            size="is-medium"
                                            type="is-primary"
                                            passive-type="default"
                                            v-model="
                                                require_firma_empleador_temp
                                            "
                                            :rounded="false"
                                            :disabled="!activo"
                                            @input="
                                                toggleRequiereFirmaEmpleador
                                            "
                                        >
                                            <span
                                                >DOCUMENTO REQUIERE FIRMA DEL
                                                <b>EMPLEADOR</b></span
                                            >
                                        </b-switch>
                                    </b-field>
                                    <br />
                                    <transition name="slide">
                                        <configuracion
                                            v-if="require_firma_empleador"
                                            firmante="EMPLEADOR"
                                            :tipo_documento_id="
                                                $route.params.id
                                            "
                                            :only_link="true"
                                        />
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

import Info from '@/components/TiposDocumentos/Info';
import Configuracion from '@/components/TiposDocumentos/Configuracion.vue';

export default {
    components: {
        Info,
        Configuracion,
    },
    data() {
        return {
            require_firma: false,
            require_firma_temp: false,
            require_firma_empleador: false,
            require_firma_empleador_temp: false,
            activo: false,
            descripcion: '',
        };
    },
    computed: {
        documento() {
            return this.$store.state.tiposDocumentos.selected;
        },
        isLoading() {
            return this.$store.state.tiposDocumentos.isLoading;
        },
    },
    async mounted() {
        await this.fetchDocument(this.$route.params.id);
        this.loadData(this.documento);
    },
    methods: {
        async fetchDocument(id) {
            await this.$store.dispatch('tiposDocumentos/show', id);
        },
        async updateTipoDocumento(id, data) {
            const res = await this.$store.dispatch('tiposDocumentos/update', {
                id,
                data,
            });
            return res;
        },
        loadData(val) {
            this.require_firma = val.require_firma;
            this.require_firma_temp = val.require_firma;
            this.activo = val.activo;
            this.descripcion = val.descripcion;
            this.require_firma_empleador = val.require_firma_empleador;
            this.require_firma_empleador_temp = val.require_firma_empleador;
        },
        async save() {
            const { value } = await Swal.fire({
                title: 'Actualizar',
                text: '¿Desea actualizar el nombre de este TIPO DE DOCUMENTO?',
                confirmButtonText: 'Guardar',
                showCancelButton: true,
            });

            if (!value) {
                this.loadData(this.documento);
                return;
            }

            try {
                Swal.fire({
                    didOpen: () => Swal.showLoading(),
                });

                const res = await this.updateTipoDocumento(this.documento.id, {
                    ...this.documento,
                    descripcion: this.descripcion,
                });

                Swal.fire({
                    title: res.message,
                    icon: 'success',
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: 'error',
                });
            }
        },
        async toggleRequiereFirma() {
            const { value } = await Swal.fire({
                title: 'Cambiar requerimiento de firma',
                icon: 'warning',
                text: 'La modificación se realizará para los documentos subidos posteriores a este cambio',
                confirmButtonText: 'Guardar',
                showCancelButton: true,
            });

            if (!value) {
                this.require_firma_temp = this.require_firma;
                return;
            }

            try {
                Swal.fire({
                    didOpen: () => Swal.showLoading(),
                });

                const res = await this.updateTipoDocumento(this.documento.id, {
                    ...this.documento,
                    require_firma: !this.documento.require_firma,
                });

                this.loadData(res.data);

                Swal.fire({
                    title: res.message,
                    icon: 'success',
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: 'danger',
                });
            }
        },
        async toggleRequiereFirmaEmpleador() {
            const { value } = await Swal.fire({
                title: 'Cambiar requerimiento de firma del EMPLEADOR',
                icon: 'warning',
                text: 'La modificación se realizará para los documentos subidos posteriores a este cambio',
                confirmButtonText: 'Guardar',
                showCancelButton: true,
            });

            if (!value) {
                this.require_firma_empleador_temp =
                    this.require_firma_empleador;
                return;
            }

            try {
                Swal.fire({
                    didOpen: () => Swal.showLoading(),
                });

                const res = await this.updateTipoDocumento(this.documento.id, {
                    ...this.documento,
                    require_firma_empleador:
                        !this.documento.require_firma_empleador,
                });

                this.loadData(res.data);

                Swal.fire({
                    title: res.message,
                    icon: 'success',
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: 'danger',
                });
            }
        },
        async toggleActivo(activo) {
            const { value } = await Swal.fire({
                title: `${activo ? 'Habilitar' : 'Deshabilitar'} documento`,
                icon: 'warning',
                text: `¿Desea ${
                    activo ? 'habilitar' : 'deshabilitar'
                } este documento? ${
                    !activo &&
                    ' No podrá subir este tipo de documentos mientras este desactivado'
                }`,
                confirmButtonText: 'Guardar',
                showCancelButton: true,
            });

            if (!value) {
                return;
            }

            try {
                Swal.fire({
                    didOpen: () => Swal.showLoading(),
                });

                const res = await this.updateTipoDocumento(this.documento.id, {
                    ...this.documento,
                    activo,
                });

                this.loadData(res.data);

                Swal.fire({
                    title: res.message,
                    icon: 'success',
                });
            } catch (err) {
                Swal.fire({
                    title: err.message,
                    icon: 'danger',
                });
            }
        },
        cancel() {
            this.loadData(this.documento);
        },
    },
};
</script>

<style lang="scss" scoped>
.preview--container {
    display: flex;
    justify-content: center;
}

.text-input {
    width: 100%;
    display: flex;
    align-items: center;

    & > input {
        background: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 0.5rem;
        width: 90%;

        font-size: 1.5em;
        font-weight: 600;
        color: #5c4cb8;

        &:hover,
        &:focus {
            border: 1px solid #dbdbdb;
        }

        &:focus ~ .controls {
            display: flex;
        }
    }

    & > .controls {
        display: none;
        justify-content: space-around;
        width: 10%;

        &:hover {
            display: flex;
        }
    }
}
</style>
